import React from 'react'
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter'

import Layout from '../components/layout/layout'
import FadeInSection from '../components/fadeinsection'
import PageHeader from '../components/pageheader'
import Button from '../components/button'
import ResponsiveHeader from '../components/responsiveheader'

const TeamMember = ({ name, bio, twitter }) => (
  <div className="grid mb-6 rounded-lg">
    <img
      className="object-cover object-left max-h-72 md:min-h-half-screen w-full"
      style={{ gridArea: '1/1' }}
      src={`/team/${name.toLowerCase()}.jpg`}
      alt="Abby"
    />

    <div
      className="grid content-end rounded-b-md z-10"
      style={{ gridArea: '1/1' }}
    >
      <div className="flex flex-col bg-black bg-opacity-50 rounded-t-lg p-4">
        <ResponsiveHeader size={0} className="uppercase">
          <div className="flex items-center">
            <div className="flex-1">{name}</div>
            {twitter && <Button
              action={`https://twitter.com/${twitter}`}
              theme="blue"
              size="small"
            >
              <FaTwitter className="inline lg:mr-2" />
              <span className="hidden lg:inline-block">{twitter}</span>
            </Button>}
          </div>
        </ResponsiveHeader>

        <p className="mt-0 md:mt-4 text-white text-xs lg:text-sm leading-relaxed text-justify items-center">
          {bio}
        </p>
      </div>
    </div>
  </div>
)

const TeamPage = () => {
  return (
    <Layout>
      <PageHeader title="Meet the team" className="mt-12" />
      <p className="font-medium text-lg lg:text-xl text-gray-300 mb-12">
        Much like the blockchain technologies that have helped redefine
        the creative economy, we believe that all NFT projects should be
        built upon openness and transparency. That means a present and active team within the community and no anonymous
        devs. It's the main reason we are fully non-anon and decided to show our faces—come say hi!
        <br /><br />
        All of the team <em>(except Andrew, for now...)</em> quit their 9-5 to focus on Top Dogs full-time. We're also extremely proud to say that we pay every single one of our team. We want them to share in the projects success—no volunteers here.
      </p>

      <FadeInSection delay="0.2s">
        <div className="flex flex-col md:flex-row w-full min-h-half-screen">
          <div className="w-full md:mr-6">
            <TeamMember
              name="Paul"
              twitter="darkp0rt"
              bio="Builder, creator, and breaker of things. NFT degen. Previously head of cyber at a Saudi conglomerate and security lead at a world-renowned law firm, protecting the privacy of the world's most successful people. 15+ years experience in software development across e-commerce, events, and gaming industries."
            />
          </div>

          <div className="w-full md:mr-6">
            <TeamMember
              name="Abby"
              twitter="pixellionart"
              bio="Self-taught artist specialising in digital, oil, watercolour and mixed media. Former English teacher. International adventurer! Lived in Munich, Johannesburg &amp; Berlin; now in England. Movie buff &amp; Buffy fan. ❤ Sci Fi &amp; time travel."
            />
          </div>
        </div>
      </FadeInSection>

      <FadeInSection delay="0.35s">
        <div className="flex flex-col md:flex-row w-full min-h-half-screen">
          <div className="w-full md:mr-6">
            <TeamMember
              name="Pete"
              twitter="PeteMcGranaghan"
              bio="Father, gamer, poker player and now crypto/NFT degen. 9 years of professional IT experience, starting in support ('have you tried turning it off and on again...') and ending up in development ('have I tried turning it off and on again...'). Survives solely on coffee."
            />
          </div>

          <div className="w-full md:mr-6">
            <TeamMember
              name="Jakub"
              twitter="MadeByPanda_"
              bio="Chief Meme Officer. Nickname: Panda. I am a beer &amp; chicken connoisseur and my steam library is bigger than most PFP projects. Tried to learn to paint, but instead spent few hundred hours within a single month on Udemy so that I can now call myself a software developer. I love neon street signs and colourful pop art."
            />
          </div>
        </div>
      </FadeInSection>

      <FadeInSection delay="0.5s">
        <div className="flex flex-col md:flex-row w-full min-h-half-screen">
          <div className="w-full md:mr-6">
            <TeamMember
              name="Andrew"
              bio="Learned how to program as a kid before programming was cool, and before YouTube existed. Went on to do a computer science degree and turned it into a career for the past 15 years. Can't write descriptions."
            />
          </div>

          <div className="w-full md:mr-6"></div>
        </div>
      </FadeInSection>
    </Layout>
  )
}

export default TeamPage
