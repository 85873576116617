import React, { useState, useEffect, useRef } from 'react'

const FadeInSection = ({ children, delay = '0s' }) => {
  const [isVisible, setVisible] = useState(false)
  const domRef = useRef()

  // do we need to debounce here?
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => entry.isIntersecting && setVisible(true))
    })

    observer.observe(domRef.current)

    return () => observer.disconnect(domRef.current)
  })

  return (
    <div className={`transition-all ${isVisible ? 'slide-up-fade-in' : 'invisible opacity-0'}`} style={{ animationDelay: delay }} ref={domRef}>
      {children}
    </div>
  )
}

export default FadeInSection
